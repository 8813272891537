import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import Button from "../components/Button"
import ComingUp from "../components/ComingUp"
import Container from "../components/Container"
import Doom from "../components/Doom"
import MediaFigureLink from "../components/MediaFigureLink"
import SiteLink from "../components/SiteLink"
import Svg from "../components/Svg"
import { gimmeTheTag, formatLink, siteHeaderMinistryLinks, formatResourceLinkHref, formatResourceLinkLabel } from "../helpers"
import { useProviderSearch } from "../hooks"

const SiteHeader = () => {
  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          allDatoCmsGlobal {
            edges {
              node {
                resourcesLinks {
                  resourcesShortLabel
                  title
                  slug
                  slugPath
                }
              }
            }
          }
          allDatoCmsMinistry(sort: {order: ASC, fields: priority}) {
            edges {
              node {
                slug
                name
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <header className="hcc-header" role="banner" data-datocms-noindex>
            <nav className="hcc-nav">
              <Container tag="ul">
                <NavLogo/>
                <NavDesktop data={data}/>
                <NavMobile data={data}/>
                <NavSearch/>
              </Container>
            </nav>
          </header>
        )
      }}
    />
  )
}

export default SiteHeader


// Global Nav: Top Links and Dropdowns
// -----------------------------------

const NavGlobalTopLink = ({href, label, ...props}) => {
  let classes = ['hcc-nav-top-link']

  // if (window.location.pathname === href) {
  //   classes.push('is-current')
  // }

  return (
    <SiteLink href={href} className={classes.join(' ')} {...props}><span>{label}</span></SiteLink>
  )
}

const NavGlobalTopToggle = ({label, ...props}) => {
  const onMouseDown = e => {
    e.preventDefault()
  }

  return (
    <button className="hcc-nav-top-toggle" onMouseDown={onMouseDown} {...props}><span>{label}</span></button>
  )
}

const NavGlobalDropdownSection = ({title, children}) => (
  <div className="hcc-nav-dropdown-section">
    {title && <h2 className="hcc-nav-dropdown-title">{title}</h2>}
    {children}
  </div>
)

const NavGlobalDropdown = ({type, hitBox, timeout = 50, children}) => {

  let timer               = null
  let [active, setActive] = useState(false)
  let topLinkClassNames   = ['hcc-nav-top-link']
  let dropdownClassNames  = ['hcc-nav-dropdown', `is-${type}`]

  // if (window.location.pathname === hitBox.props.href) {
  //   topLinkClassNames.push('is-current')
  // }

  if (active) {
    dropdownClassNames.push('is-active')
    topLinkClassNames.push('is-active')
  }

  let show = () => {
    clearTimeout(timer)
    setActive(true)
  }

  let hide = () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      setActive(false)
    }, timeout)
  }

  // let onClickNavHasDropdown = e => {
  //   if (e.target.hasAttribute('href') || e.target.closest('[href]')) {
  //     hide()
  //   }
  // }

  return (
    <div
      className    = "hcc-nav-has-dropdown"
      role         = "none"
      onFocus      = {show}
      onBlur       = {hide}
      onMouseEnter = {show}
      onMouseLeave = {hide}
      // onClick      = {onClickNavHasDropdown}
    >
      {React.cloneElement(
        hitBox,
        {
          className       : topLinkClassNames.join(' '),
          'aria-expanded' : active,
          'aria-haspopup' : 'true',
          'aria-controls' : `hcc-nav-dropdown-${type}`,
        },
        null
      )}
      <div id={`hcc-nav-dropdown-${type}`} className={dropdownClassNames.join(' ')} aria-hidden={! active}>
        {children}
      </div>
    </div>
  )

}


// Global Nav: Sections
// --------------------

const NavLogo = () => (
  <li className="hcc-nav-logo">
    <span className="hcc-header-icon-overflow">
      <NavGlobalTopLink
        href="/"
        label={
          <>
            <span className="is-symbol"><Svg type="logo-symbol"/></span>
            <span className="is-wordmark"><Svg type="logo-wordmark"/></span>
            <em className="is-accessibility-text">Hillside Community Church</em>
          </>
        }
      />
    </span>
  </li>
)

const NavDesktop = ({data}) => {
  let allResourceLinks = data.allDatoCmsGlobal.edges[0].node.resourcesLinks

  return (
    <li className="hcc-nav-desktop">
      <NavGlobalDropdown type="new" hitBox={<NavGlobalTopLink href="/plan-your-visit/" label="I'm New"/>}>
        <NavGlobalDropdownSection title="Welcome">
          <MediaFigureLink href="/plan-your-visit/" label="Plan Your Visit" meta="Come and meet our community" figure="plan" ellipsis={false}/>
          {<MediaFigureLink href={formatLink('', 'go')} label="Go:Classes" meta="Learn about God&apos;s story in your life" figure="location-marker" ellipsis={false}/>}
          {/*<MediaFigureLink href="/go-classes/" label="Go:Classes" meta="Learn about God&apos;s story in your life" figure="location-marker" ellipsis={false}/>*/}
        </NavGlobalDropdownSection>
      </NavGlobalDropdown>
      <NavGlobalDropdown type="ministries" hitBox={<NavGlobalTopLink href={formatLink('', 'm')} label="Ministries"/>}>
        <NavGlobalDropdownSection title="Connect"><div className="hcc-grid">{siteHeaderMinistryLinks(data.allDatoCmsMinistry.edges, 'main', true)}</div></NavGlobalDropdownSection>
      </NavGlobalDropdown>
      <NavGlobalTopLink href="/life-groups/" label="Life Groups"/>
      <NavGlobalTopLink href="/serve/" label="Serve"/>
      <NavGlobalDropdown type="events" hitBox={<NavGlobalTopLink href={formatLink('', 'e')} label="Events"/>}>
        <NavGlobalDropdownSection title="Coming Up"><ComingUp meta={true} accent={true}/></NavGlobalDropdownSection>
        <NavGlobalDropdownSection title="By Ministry"><div className="hcc-grid is-mini">{siteHeaderMinistryLinks(data.allDatoCmsMinistry.edges, 'events', false)}</div></NavGlobalDropdownSection>
      </NavGlobalDropdown>
      <NavGlobalDropdown type="resources" hitBox={<NavGlobalTopLink href="/resources/" label="Resources"/>}>
        <NavGlobalDropdownSection title="Learn">
          <MediaFigureLink href={formatLink('', 't')} label="Talks" meta="Main and student services" figure="video"/>
        </NavGlobalDropdownSection>
        <NavGlobalDropdownSection title="Miscellaneous">
          <div className="hcc-grid is-mini">
            <MediaFigureLink href="/about/" label="About" figure={false}/>
            {allResourceLinks.map((dataLink, iLink) => {
              return (
                <MediaFigureLink
                  key    = {`desktop-resource-link-${iLink}`}
                  href   = {formatResourceLinkHref(dataLink)}
                  label  = {formatResourceLinkLabel(dataLink)}
                  figure = {false}
                />
              )
            })}
          </div>
        </NavGlobalDropdownSection>
      </NavGlobalDropdown>
      <NavGlobalTopLink href="/give/" label="Give"/>
    </li>
  )
}

const NavMobile = ({data}) => {

  let [active, setActive] = useState(false)
  let menuClassNames      = ['hcc-nav-mobile-menu']

  if (active) {
    menuClassNames.push('is-active')
  }

  let show = () => { setActive(true)  }
  let hide = () => { setActive(false) }

  // let onClickNavMobileMenu = e => {
  //   if (e.target.hasAttribute('href') || e.target.closest('[href]')) {
  //     hide()
  //   }
  // }

  const onMouseDownClose = e => {
    e.preventDefault()
  }

  let allResourceLinks = data.allDatoCmsGlobal.edges[0].node.resourcesLinks

  return (
    <li className="hcc-nav-mobile">
      <span className="hcc-header-icon-overflow">
        <NavGlobalTopToggle label={<><Svg type="feed"/><em className="is-accessibility-text">Navigation</em></>} onClick={show}/>
      </span>
      <div className={menuClassNames.join(' ')}>
        <button onClick={hide} onMouseDown={onMouseDownClose} aria-label="Close"><Svg type="close"/></button>
        <div className="hcc-nav-mobile-section">
          <h6 className="hcc-nav-mobile-headline">I'm New</h6>
          <div className="hcc-grid">
            <SiteLink href="/plan-your-visit/">Plan Your Visit</SiteLink>
            {/*<SiteLink href={formatLink('', 'go')}>Go:Classes</SiteLink>*/}
            <SiteLink href={formatLink('discover', 'go')}>Go:Discover</SiteLink>
          </div>
        </div>
        <div className="hcc-nav-mobile-section">
          <h6 className="hcc-nav-mobile-headline">Ministries</h6>
          <div className="hcc-grid">
            {siteHeaderMinistryLinks(data.allDatoCmsMinistry.edges, 'main', false)}
          </div>
        </div>
        <div className="hcc-nav-mobile-section">
          <h6 className="hcc-nav-mobile-headline">Connect</h6>
          <div className="hcc-grid">
            <SiteLink href="/life-groups/">Life Groups</SiteLink>
            <SiteLink href="/serve/">Serve</SiteLink>
            <SiteLink href={formatLink('', 'e')}>Events</SiteLink>
          </div>
        </div>
        <div className="hcc-nav-mobile-section">
          <h6 className="hcc-nav-mobile-headline">Resources</h6>
          <div className="hcc-grid">
            <SiteLink href={formatLink('', 't')}>Talks</SiteLink>
            <SiteLink href="/about/">About</SiteLink>
            {allResourceLinks.map((dataLink, iLink) => {
              return (
                <SiteLink key={`mobile-resource-link-${iLink}`} href={formatResourceLinkHref(dataLink)}>
                  {formatResourceLinkLabel(dataLink)}
                </SiteLink>
              )
            })}
          </div>
        </div>
        <div className="hcc-nav-mobile-section">
          <Button label="Give with Pushpay" href="https://pushpay.com/g/yourhillsidekeller"/>
        </div>
      </div>
    </li>
  )

}

const NavSearch = () => {

  // return (
  //   <li className="hcc-nav-search">
  //     <span className="hcc-header-icon-overflow">
  //       <NavGlobalTopToggle label={<><Svg type="search"/><em className="is-accessibility-text">Search</em></>}/>
  //     </span>
  //   </li>
  // )

  let [active, setActive]                                  = useState(false)
  let [searchRef, results, isSearching, clearQuery, query] = useProviderSearch()
  let widgetClassNames                                     = ['hcc-nav-search-widget']
  let resultsClassNames                                    = ['hcc-search-results']
  let hasQuery                                             = query.length !== 0
  let hasResponse                                          = Array.isArray(results)
  let hasResults                                           = results?.length > 0

  if ( active )      { widgetClassNames.push('is-active')    }
  if ( isSearching ) { widgetClassNames.push('is-searching') }
  if ( hasResponse ) { resultsClassNames.push('is-active')   }

  let show = () => { setActive(true)  }
  let hide = () => { setActive(false) }

  const onTransitionEndWidget = e => {
    if ( active ) {
      searchRef.current.focus()
    }
  }

  const onClickX = e => {
    hide()
    setTimeout(() => {
      clearQuery()
    }, 350)
  }

  const onMouseDownX = e => {
    e.preventDefault()
  }

  return (
    <li className="hcc-nav-search">
      <span className="hcc-header-icon-overflow">
        <NavGlobalTopToggle label={<><Svg type="search"/><em className="is-accessibility-text">Search</em></>} onClick={show}/>
      </span>
      <div className={widgetClassNames.join(' ')} onTransitionEnd={onTransitionEndWidget}>
        <div className="hcc-search-form">
          <input
            type        = "search"
            placeholder = "Search…"
            ref         = {searchRef}
          />
          <div className="is-status">
            <Svg type="search"/>
            <div className="is-loading-indicator"><i/></div>
          </div>
          <button onClick={onClickX} onMouseDown={onMouseDownX} type="button" aria-label="Close"><Svg type="close"/></button>
          <div className={resultsClassNames.join(' ')}>
            {(hasResponse && ! hasResults && hasQuery) &&
              <b>No Results Found</b>
            }
            {(hasResponse && hasResults && hasQuery) &&
              <ul>
                {results.map((result, i, arr) => {

                  let theUrl   = result.url
                  let theLabel = 'Page'
                  let a        = document.createElement('a')
                  a.href       = theUrl
                  let Tag      = gimmeTheTag(a.pathname)

                  switch ( true ) {
                    case theUrl === 'https://www.yourhillside.com/' :
                      theLabel = 'Home'
                      break
                    case theUrl.includes('/blog/') :
                      theLabel = 'Blog Post'
                      break
                    case theUrl.includes('/events/') :
                      theLabel = 'Event'
                      break
                    case theUrl.includes('/groups/') :
                      theLabel = 'Group'
                      break
                    case theUrl.includes('/ministries/') :
                      theLabel = 'Ministry'
                      break
                    case theUrl.includes('/presentations/') :
                      theLabel = 'Presentation'
                      break
                    case theUrl.includes('/series/') :
                      theLabel = 'Series'
                      break
                    case theUrl.includes('/speakers/') :
                      theLabel = 'Speaker'
                      break
                    case theUrl.includes('/talks/') :
                      theLabel = 'Talk'
                      break
                    default :
                      theLabel = ''
                      break
                  }

                  return (
                    <li key={result.id ?? i}>
                      <Tag href={a.pathname}>
                        <article>
                          <span>{theLabel}</span>
                          <Doom tag="h6" content={result.title}/>
                          <Doom tag="p" content={result.body}/>
                        </article>
                      </Tag>
                    </li>
                  )
                })}
              </ul>
            }
          </div>
        </div>
      </div>
    </li>
  )

}
