// =============================================================================
// HELPERS.JS
// -----------------------------------------------------------------------------
// I get by with a little help from my friends...
// =============================================================================

// =============================================================================
// TABLE OF CONTENTS
// -----------------------------------------------------------------------------
//   01. Imports
//   02. ministrySlugs()
//   03. randomMinistrySlug()
//   04. randomHex()
//   05. ministryHex()
//   06. formatLink()
//   07. gimmeTheTag()
//   08. objAssign()
//   09. slugify()
//   10. capitalize()
//   11. splitArray()
//   12. siteHeaderMinistryLinks()
//   13. siteHeaderEventLinks()
//   14. formatTime()
//   15. glueString()
//   16. fauxUrlParams()
//   17. getURLSearchParams()
//   18. formatResourceLinkHref()
//   19. formatResourceLinkLabel()
// =============================================================================

// Imports
// =============================================================================

import React from "react"
import MediaFigureLink from "./components/MediaFigureLink"
import SiteLink from "./components/SiteLink"
import Svg from "./components/Svg"
import * as moment from "moment"
import { sample } from "lodash"
// import { navigate } from "@reach/router"



// ministrySlugs()
// =============================================================================

export function ministrySlugs() {
  return ['life-groups', 'men', 'women', 'students', 'children', 'young-adults', 'creative', 'missions', 'care']
}



// randomMinistrySlug()
// =============================================================================

export function randomMinistrySlug() {
  return sample(ministrySlugs())
}



// randomHex()
// =============================================================================

export function randomHex() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16)
}




// ministryHex()
// =============================================================================

export function ministryHex(ministry) {
  let theHex = null

  switch (ministry) {
    case 'life-groups'  : theHex = "#4ea9fc"; break
    case 'men'          : theHex = "#3862ea"; break
    case 'women'        : theHex = "#f75e8b"; break
    case 'students'     : theHex = "#fcae4e"; break
    case 'children'     : theHex = "#3ccb95"; break
    case 'young-adults' : theHex = "#16c1c7"; break
    case 'worship'      : theHex = "#fd7e4f"; break
    case 'missions'     : theHex = "#7539c9"; break
    case 'care'         : theHex = "#ff4c59"; break
    default :
      theHex = "#000000"
  }

  return theHex
}



// formatLink()
// =============================================================================
// 01. Accepts a `slug` and `context` which are utilized to format the final
//     link output. The `slug` parameter should be a string and the `context`
//     parameter should be one of the following:

export function formatLink(slug = 'men', context = 'm') {
  let path = ['/']

  if (slug === '' && context === 'm') {
    path.push('ministries/')
    return path.join('')
  }

  switch (context) {
    case 'b'  : path.push('blog/');          break
    case 'e'  : path.push('events/');        break
    case 'go' : path.push('go/');            break
    case 'm'  : path.push('');               break
    case 'mb' : path.push('');               break
    case 'p'  : path.push('presentations/'); break
    case 'se' : path.push('series/');        break
    case 'sp' : path.push('speakers/');      break
    case 't'  : path.push('talks/');         break
    default   :
      return
  }

  if (!! slug) {
    path.push(`${slug}/`)
  }

  return path.join('')
}



// gimmeTheTag()
// =============================================================================
// 01. If only an `href` is provided, will determine if a standard anchor tag
//     or the <SiteLink/> component should be used. If a `tag` is provided,
//     it will also test for that. Anchors will always take precedence over the
//     provided tag.

export function gimmeTheTag(href = null, tag = null) {
  return href !== null && href.includes('http') && ! href.includes('yourhillside.com') ? 'a' : href !== null ? SiteLink : tag
}



// objAssign()
// =============================================================================
// 01. The empty object in the first parameter ensures that we get a pure copy
//     and leave the original alone, then we overwrite key/property pairs.

export function objAssign(defaults = {}, overwrites = {}) {
  return Object.assign({}, defaults, overwrites)
}



// slugify()
// =============================================================================
// 01. Capture groups of whitespace and non-word characters to replace with `-`
//     as a separator between words.
// 02. Trim from start of text.
// 03. Trim from end of text.

export function slugify(text, delimiter = '-') {
  return text.toString().toLowerCase()
    .replace(/&/, 'and')
    .replace(/\+/, 'plus')
    .replace(/([\s\W]+)/g, delimiter) // 01
    .replace(/^-+/, '')               // 02
    .replace(/-+$/, '');              // 03
}



// capitalize()
// =============================================================================

export function capitalize(str) {
  str = str.split(" ")
  for (let i = 0, x = str.length; i < x; i++) {
    str[i] = str[i][0].toUpperCase() + str[i].substr(1)
  }
  return str.join(" ")
}



// splitArray()
// =============================================================================
// 01. Divides an array in half and returns an object to access either the
//     `first` or `last` half of the array.
// 02. Use .ceil() to return larger half first and .floor() to return larger
//     half last.`

export function splitArray(arr) {
  let mid   = Math.ceil(arr.length / 2) // 02
  let first = arr.slice(0, mid)
  let last  = arr.slice(mid, arr.length)
  return {first : first, last : last}
}



// siteHeaderMinistryLinks()
// =============================================================================
// 01. Formatting for ministry links in the <SiteHeader/> component.

export function siteHeaderMinistryLinks(data, key = 'main', ellipsis) {
  let links = []
  data.map(({node : ministry}) => {
    links.push(
      <MediaFigureLink
        key      = {`site-header-ministry-${key}-${ministry.slug}`}
        href     = {key === 'main' ? formatLink(ministry.slug, 'm') : `${formatLink('', 'e')}?ministry=${ministry.slug}`}
        figure   = {key === 'main' && ministry.slug}
        label    = {ministry.name}
        ellipsis = {ellipsis}
        style    = {
          key === 'main' ?
          {
            '--media-figure-background-color'          : `var(--c-${ministry.slug})`,
            '--media-figure-link-background-color-int' : `var(--c-${ministry.slug})`,
          } :
          null
        }
        // onClick = {key === 'main' ? () => {} : () => {
        //   navigate(`/events/?ministry=${ministry.slug}`)
        // }}
      />
    )
    return true
  })
  return links
}



// formatTime()
// =============================================================================
// `format` accepts `datetime-local` or `date`

export function formatTime(input, context = 'timeLong', inputFormat = 'datetime-local') {
  let   d = null
  let   c = null
  let   f = inputFormat === 'datetime-local' ? 'YYYY-MM-DDTHH:mm:ss-Z' : 'YYYY-MM-DD'
  const m = moment(input, f)

  switch (true) {

    case context === 'blogIndex' :
      d = m.format('YYYY-MM-DD')
      c = <React.Fragment><b>{m.format('D')}</b><i>{m.format('MMM')}</i></React.Fragment>
      break

    case context === 'eventDateWithMonth' :
      d = m.format('YYYY-MM-DD')
      c = <React.Fragment><b>{m.format('D')}</b><i>{m.format('MMM')}</i></React.Fragment>
      break

    case context === 'eventDateWithDay' :
      d = m.format('YYYY-MM-DD')
      c = <React.Fragment><b>{m.format('D')}</b><i>{m.format('ddd')}</i></React.Fragment>
      break

    case context === 'eventTimeline' :
      d = m.toISOString()
      c = <React.Fragment><b>{m.format('D')}</b><i><span>{m.format('ddd')}</span></i></React.Fragment>
      break

    case context === 'eventSingleStart' :
      d = m.toISOString()
      c = <React.Fragment>{m.format('MMM D')} <Svg type="at"/> {m.format('h:mma')}</React.Fragment>
      break

    case context === 'eventEndLong' :
      d = m.toISOString()
      c = m.format('MMM D')
      break

    case context === 'talkFull' :
      d = m.toISOString()
      c = m.format('MMM DD, YYYY')
      break

    case context === 'postFull' :
      d = m.toISOString()
      c = m.format('MMMM DD, YYYY')
      break

    case context === 'ftron' :
      d = m.toISOString()
      c = m.format('MMM. D')
      break

    case context === 'yearLong' :
      d = m.format('YYYY')
      c = m.format('YYYY')
      break

    case context === 'yearShort' :
      d = m.format('YYYY')
      c = m.format('YY')
      break

    case context === 'monthLong' :
      d = m.format('YYYY-MM')
      c = m.format('MMMM')
      break

    case context === 'monthShort' :
      d = m.format('YYYY-MM')
      c = m.format('MMM')
      break

    case context === 'dateLong' :
      d = m.format('YYYY-MM-DD')
      c = m.format('Do')
      break

    case context === 'dateShort' :
      d = m.format('YYYY-MM-DD')
      c = m.format('D')
      break

    case context === 'dayLong' :
      d = m.format('YYYY-MM-DD')
      c = m.format('dddd')
      break

    case context === 'dayShort' :
      d = m.format('YYYY-MM-DD')
      c = m.format('ddd')
      break

    case context === 'dayShortest' :
      d = m.format('YYYY-MM-DD')
      c = m.format('dd')
      break

    case context === 'timeLong' :
      d = m.format('HH:mm:ss')
      c = m.format('h:mma')
      break

    case context === 'timeShort' :
      d = m.format('HH:mm:ss')
      c = m.format('h:mm')
      break

    default :
      d = m.toISOString()
      c = m.format('MMM DD, YYYY')
      break
  }

  return <time dateTime={d}>{c}</time>
}



// glueString()
// =============================================================================

export function glueString(string) {
  var reconstructed = ''

  string.split(' ').forEach(function(word, i, arr) {
    if ( i === 0 ) {
      reconstructed += word
    } else if ( i === arr.length - 1 && (arr[i].length + arr[i - 1].length) < 14 ) {
      reconstructed += `&nbsp;${word}`
    } else {
      reconstructed += ` ${word}`
    }
  })

  return reconstructed
}



// fauxUrlParams()
// =============================================================================

export function fauxUrlParams() {
  return { has : () => { return false }, get : () => { return false }, set : () => { return false }, toString : () => { return '' } }
}



// getURLSearchParams()
// =============================================================================

export function getURLSearchParams() {
  return typeof window === 'undefined' ? fauxUrlParams() : new URLSearchParams(window.location.search)
}



// formatResourceLinkHref()
// =============================================================================

export function formatResourceLinkHref(data) {
  return data.slugPath !== '' ? `/${data.slugPath}/${data.slug}/` : `/${data.slug}/`
}



// formatResourceLinkLabel()
// =============================================================================

export function formatResourceLinkLabel(data) {
  return data.resourcesShortLabel !== null ? data.resourcesShortLabel : data.title
}
